:root {
  --color-text: #000;
  --color-background: #fff;

  --color-primary: hsl(204, 100%, 50%);
  --color-secondary: hsl(206, 100%, 47%);
  --color-accent: hsl(258, 100%, 67%);
  --color-highlight: hsl(196, 100%, 95%);

  --color-gray: #525252;
  --color-muted: #e5e5e5;
  --color-washed: #f5f5f5;
  --color-canvas: #fafafa;

  --color-error: rgb(244, 67, 54);
  --color-error-text: rgb(97, 26, 21);
  --color-error-background: rgb(253, 236, 234);

  --color-warning: rgb(255, 152, 0);
  --color-warning-text: rgb(102, 60, 0);
  --color-warning-background: rgb(255, 244, 229);

  --color-info: rgb(33, 150, 243);
  --color-info-text: rgb(13, 60, 97);
  --color-info-background: rgb(232, 244, 253);

  --color-success: rgb(76, 175, 80);
  --color-success-text: rgb(30, 70, 32);
  --color-success-background: rgb(237, 247, 237);
}

:root[data-color-mode='dark'] {
  --color-text: #fff;
  --color-background: #000;

  --color-primary: hsl(336, 100%, 67%);
  --color-secondary: hsl(334, 100%, 71%);
  --color-accent: hsl(180, 73%, 50%);
  --color-highlight: hsl(346, 100%, 18%);

  --color-gray: #a3a3a3;
  --color-muted: #262626;
  --color-washed: #171717;
  --color-canvas: #060606;

  --color-error: rgb(244, 67, 54);
  --color-error-text: rgb(250, 179, 174);
  --color-error-backgrund: rgb(24, 6, 5);

  --color-warning: rgb(255, 152, 0);
  --color-warning-text: rgb(255, 213, 153);
  --color-warning-background: rgb(25, 15, 0);

  --color-info: rgb(33, 150, 243);
  --color-info-text: rgb(166, 213, 250);
  --color-info-background: rgb(3, 14, 24);

  --color-success: rgb(76, 175, 80);
  --color-success-text: rgb(183, 223, 185);
  --color-success-background: rgb(7, 17, 7);
}
